import React, { useState, useEffect } from 'react';
import { Radio, List,Checkbox,Col, Row, Button, Input, Form, Switch, Select, DatePicker, Alert, Card, message } from 'antd';
import { EyeOutlined, DeleteOutlined, PlusOutlined, HistoryOutlined, CommentOutlined, CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import DraggableModal from './DraggableModal';
import { API } from "../constant";
import { formConfig } from "../configs/ProfilAnerkennung.js";
import { getToken, formatChanges } from "../helpers";

const { Option } = Select;

const StatusEnum = {
  NOT_SUBMITTED: 'Noch nicht eingereicht',
  SUBMITTED_IN_PROGRESS: 'Eingereicht - in Bearbeitung',
  SUBMITTED_REJECTED: 'Eingereicht - abgelehnt',
  SUBMITTED_ACCEPTED: 'Eingereicht - angenommen',
};

const KurseList = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isKommentarModalVisible, setIsKommentarModalVisible] = useState(false);
  const [isRevision, setIsRevision] = useState(false);
  const [currFilter, setCurrFilter] = useState(StatusEnum.SUBMITTED_IN_PROGRESS);
  const [currSearch, setCurrSearch] = useState("");
  const [currentKurs, setCurrentKurs] = useState(null);
  const [showAll, setShowAll] = useState(false)
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);

  const location = useLocation();
  useEffect(() => {
   if (
     location.state
   ) {
        if (location.state && location.state.open) {
          setOpen(location.state.open);
        }

        if (location.state && location.state.openApprove) {
          setOpenApprove(location.state.openApprove);
        }
    }
    // Update the ref with the current location state
  }, [location.pathname]);

  const showModal = (id) => {
    setOpen(id);
  };

    const showModalApprove = (id) => {
      setOpenApprove(id);
    };


  const handleSearchChange = (e) => {
    setCurrSearch(e.target.value);
  };

  const handleModeChange = (e) => {
    setCurrFilter(e.target.value);
  };

  const handleOk = () => {
    setOpen(false);
  };
  const handleApproveOk = () => {
    setOpenApprove(false);
  };

  const handleApproveCancel = () => {
    setOpenApprove(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const Kurse = props.veranstaltungenData;
  const showEditModal = (kurs) => {
    setCurrentKurs(kurs);
    setIsModalVisible(true);
    form.setFieldsValue(kurs);
  };

  const showKommentarModal = (kurs, isRevision) => {
    setCurrentKurs(kurs);
    setIsKommentarModalVisible(true);
    setIsRevision(!!isRevision);
  };

  const handleEdit = (dozentid) => {
   props.history.push(`/admin/aktionen/Seminare/${dozentid}`)
  };

  const handleDeny = (id, kommentar = "") => {
        fetch(`${API}/anbieter-profils/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
              body: JSON.stringify({ data: { kommentar: kommentar, status: "Eingereicht - abgelehnt" }}),
            })
              .then(response => response.json())
              .then(data => { console.log(data);
                  if (data.error) {
//                              message("", "Es ging etwas schief");
                  } else {
//                              message("", "Kurs gelöscht. .");
props.history.push('/admin/aktionen/Anbieterverwaltung', {openApprove: false, open: false })
                      window.location.reload();
                  }
              })
              .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
               });
      };

  const handleApprove = (id, anbieternummer, Gebuehr, gueltigBis = "", kommentar = "") => {

  console.debug("gueltigBis", gueltigBis);

  const gueltigBisValue = gueltigBis ? moment.utc([gueltigBis.year(), gueltigBis.month(), gueltigBis.date(), 12, 0, 0, 0]).toISOString() : null;

 fetch(`${API}/anbieter-profils/${id}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                    body: JSON.stringify({ data: { Gebuehr: Gebuehr, kommentar: kommentar, gueltigBis: new Date(gueltigBisValue), anbieternummer: anbieternummer, status: "Eingereicht - angenommen"} }),
                })
                      .then(response => response.json())
                      .then(data => { console.log(data);
                          if (data.error) {
                           message.open({ type: 'error', content: ((data.error || {}).message || "").indexOf("unique") > -1 ? "Die Anbieternummer ist schon vergeben." : 'Beim Speichern ist ein Fehler aufgetreten.', className: 'custom-class', style: { padding: '3rem', marginTop: '20vh', fontSize: '20px', backgroundColor: '#eb008c', fontWeight: 800 }, })

                          } else {
//                              message("", "Kurs gelöscht. .");
props.history.push('/admin/aktionen/Anbieterverwaltung', {openApprove: false, open: false })

                              window.location.reload();
                          }
                      })
                      .catch((error) => { console.error('Error:', error);
                            message.open({ type: 'error', content: ((error || {}).message || "").indexOf("unique") > -1 ? "Die Anbieternummer ist schon vergeben." : 'Beim Speichern ist ein Fehler aufgetreten.', className: 'custom-class', style: { padding: '3rem', marginTop: '20vh', fontSize: '20px', backgroundColor: '#eb008c', fontWeight: 800 }, })
                       });
  };

  const handleAdd = () => {
//    const newKurs = { id: Date.now(), name: '', status: StatusEnum.NOT_SUBMITTED, kommentar: '', updatedAt: moment().toISOString() };
//    setKurse([...Kurse, newKurs]);
//    showEditModal(newKurs);
  };

  return (
    <div>
    <div style={{textAlign: 'left' }}>
 <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
    <Input  onChange={handleSearchChange} style={{minWidth: 260 }} placeholder="Filter Anträge..." />
               </Col><Col md={12} lg={12} sm={24} xs={24}>
     <Radio.Group
                 onChange={handleModeChange}
                 value={currFilter}
                 style={{
                   marginBottom: 8,
                 }}
               >
                 <Radio.Button value="">Alle Anträge anzeigen</Radio.Button>
                 <Radio.Button value={StatusEnum.SUBMITTED_IN_PROGRESS}>Offene</Radio.Button>
                 <Radio.Button value={StatusEnum.SUBMITTED_REJECTED}>Abgelehnt</Radio.Button>
                 <Radio.Button value={StatusEnum.SUBMITTED_ACCEPTED}>Akzeptiert</Radio.Button>
               </Radio.Group>
               </Col>
               </Row>
               </div>
      <List
        dataSource={Kurse.filter(kurs => currFilter === "" || kurs.status === currFilter)
        .filter(kurs => currSearch === "" || JSON.stringify(kurs || {}).toLowerCase().includes((currSearch || "").toLowerCase()))
        .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
        }
        renderItem={(kurs) => (
          <Card
            bordered={false}
            style={{ marginBottom: 16 }}
          >
            <List.Item
              actions={[
                <Button icon={<EyeOutlined />}
                  onClick={() => props.history.push(`/admin/aktionen/Profil/${kurs.id}`)   }
                 >Anzeigen</Button>,
                kurs.status === StatusEnum.SUBMITTED_IN_PROGRESS ? <Button icon={<CloseCircleOutlined />} danger onClick={() => showModal(kurs.id)}>Ablehnen</Button> : null,
                kurs.status === StatusEnum.SUBMITTED_IN_PROGRESS ? <Button icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }} onClick={() => showModalApprove(kurs.id)}>{ kurs.anbieternummer ? "Änderung akzeptieren" : "Anerkennen"}</Button> : null,
              ]}
            >
              <List.Item.Meta title={((((kurs || {}).profilinformation || {})["Institut"]) ? (((kurs || {}).profilinformation || {})["Institut"]) : "") + ((kurs || {}).anbieternummer ? (" (" + (kurs || {}).anbieternummer + ")") : "")} />

              <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                              message={<span>{kurs.status} {(kurs.status === StatusEnum.SUBMITTED_REJECTED || kurs.kommentar && (
                                     <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(kurs)}>Kommentar</Button>
                                   ))}
                                   {props.isICW && (((kurs || {}).profilinformation || {}).revision && <Button icon={<HistoryOutlined />} onClick={() => showKommentarModal(kurs, true)}>Änderungen</Button>)}

                                   </span>}
                              type={
                                kurs.status === StatusEnum.NOT_SUBMITTED
                                  ? 'info'
                                  : kurs.status === StatusEnum.SUBMITTED_IN_PROGRESS
                                  ? 'warning'
                                  : kurs.status === StatusEnum.SUBMITTED_REJECTED
                                  ? 'error'
                                  : 'success'
                              }
                            /> } />

                <List.Item.Meta title={kurs.gueltigBis && <div>{ kurs.status !== StatusEnum.SUBMITTED_ACCEPTED ? (<div><span style={{color: 'red'}}>Bei letzter Genehmigung: </span><br/></div>) : (<span></span>) } <span>Gültig ab: {moment(kurs.gueltigBis).format('DD.MM.YY')}</span><br></br><span>Gültig bis: {moment(kurs.gueltigBis).add(5, 'years').format('DD.MM.YY')}</span> { kurs.Gebuehr && <div><br/><span>Gebühr: <b>{kurs.Gebuehr}</b></span></div> } </div>} />
                <List.Item.Meta title={kurs.updatedAt && <span>Letztes Update: {moment(kurs.updatedAt).format('DD.MM.YY HH:mm')}</span>} />
            </List.Item>
          </Card>
        )}
  locale={{ emptyText: 'Keine Anträge gefunden' }}
      />
      <DraggableModal
        title="Kurs bearbeiten"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Bitte wählen Sie einen Status aus' }]}
          >
            <Select>
              <Option value={StatusEnum.NOT_SUBMITTED}>Anerkennung nicht gestartet</Option>
              <Option value={StatusEnum.SUBMITTED_IN_PROGRESS}>Anerkennung in Bearbeitung</Option>
              <Option value={StatusEnum.SUBMITTED_REJECTED}>Anerkennung abgelehnt</Option>
              <Option value={StatusEnum.SUBMITTED_ACCEPTED}>Anerkannt</Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('status') === StatusEnum.SUBMITTED_REJECTED && (
            <Form.Item
              name="kommentar"
              label="Kommentar"
              rules={[{ required: true, message: 'Bitte geben Sie einen Kommentar ein' }]}
            >
              <Input.TextArea />
            </Form.Item>
          )}
        </Form>
      </DraggableModal>
      <DraggableModal
        title={ isRevision ? "Verlauf" : "Kommentar" }
        visible={isKommentarModalVisible}
        width={ isRevision ? "85vw" : "50vw" }
        onCancel={() => setIsKommentarModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsKommentarModalVisible(false)}>
            Schließen
          </Button>,
        ]}
      >
         { isRevision ? formatChanges(((currentKurs || {}).profilinformation || {}).revision, formConfig) : <div dangerouslySetInnerHTML={{ __html: currentKurs ? currentKurs.kommentar : '' }} />}
      </DraggableModal>


      <DraggableModal
                closable={true}
                open={openApprove}
                title="Anbieternummer/ Gebühr zuweisen und anerkennen"
                onOk={handleApproveOk}
                onCancel={handleApproveCancel}
                footer={null}
              > <Form
                   name="nest-messages"

                   onFinish={(values) => {
                      console.debug("values", values);

                      handleApprove(openApprove, values.anbieternummer, values.Gebuehr, values.gueltigBis, values.kommentar);
                  }}
                   fields={[
                      {
                        name: ['anbieternummer'],
                        value: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).anbieternummer,
                      },
                        {
                            name: ["Gebuehr"],
                            value: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Gebuehr,
                        }
                      ]}
                   style={{ maxWidth: 600 }}
                 >
                   <Form.Item
                   rules={[
                                               {
                                                   required: true,
                                                   message: 'Eine Anbieternummer muss vergeben werden.',
                                               },
                                           ]}
                   name={'anbieternummer'} label="Anbieternummer">
                     <Input defaultValue={(props.veranstaltungenData.find(k=> k.id === openApprove) || {}).anbieternummer} />
                   </Form.Item>
                   <Form.Item
                                         name={'Gebuehr'} label="Gebuehr">
                                             <Select  defaultValue={(props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Kursgebuehr}>
                                                <Option value="150,00 €">150,00 €</Option>
                                                <Option value="350,00 €">350,00 €</Option>
                                                <Option value="500,00 €">500,00 €</Option>
                                                <Option value="560,00 €">560,00 €</Option>
                                                <Option value="710,00 €">710,00 €</Option>
                                             </Select>
                                      </Form.Item>
<Form.Item
  rules={[
    {
      required: true,
      message: 'Ist ein Pflichtfeld.',
    },
  ]}
  name={'gueltigBis'}
  label="Anerkennung gültig ab"
><DatePicker
   format="DD-MM-YYYY"
   onChange={(date) => {

   console.debug("date",date);
     if (date) {
       form.setFieldsValue({
         gueltigBis: moment(date).clone().utc().hour(12).minute(0).second(0)
       });
     } else {
       form.setFieldsValue({ gueltigBis: null });
     }
   }}
 />

</Form.Item>


                   <Form.Item
                   name={'kommentar'} label="Kommentar">
                        <Input.TextArea />
                   </Form.Item>

                   <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
                   <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }}>
                   { (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).anbieternummer ? "Änderung akzeptieren" : "Anerkennung abschließen" }</Button>
                   </Form.Item>
                 </Form>
              </DraggableModal>

      <DraggableModal
          closable={true}
          open={open}
          title="Begründung für Ablehnung"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        > <Form
             name="nest-messages"
             onFinish={(values) => {
                console.debug("values", values);
                handleDeny(open, values.kommentar);
            }}
             style={{ maxWidth: 600 }}
           >
             <Form.Item name={'kommentar'} label="Begründung">
               <Input.TextArea />
             </Form.Item>
             <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
               <Button type="primary" htmlType="submit">
                 Mit obiger Begründung ablehnen
               </Button>
             </Form.Item>
           </Form>
        </DraggableModal>
    </div>
  );
};

export default KurseList;
