import React, { useState } from 'react';
import { Table, Row, Col, DatePicker, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

const KurseList = ({ veranstaltungenData = [], anbieterData = [] }) => {
  const [currSearch, setCurrSearch] = useState("");
  const [currFilter, setCurrFilter] = useState("Eingereicht - angenommen");
  const [dateRange, setDateRange] = useState([null, null]);

  const columns = [
    {
      title: 'Ort',
      dataIndex: ['kursinformation', 'Veranstalterort'],
      key: 'ort',
      sorter: (a, b) => (a.kursinformation.Veranstalterort || '').localeCompare(b.kursinformation.Veranstalterort || ''),
    },
    {
      title: 'Veranstalter',
      dataIndex: ['kursinformation', 'Veranstalter'],
      key: (a) => { return 'VornameVeranstalter' },
      sorter: (a, b) => (a.kursinformation['Veranstalter'] || '').localeCompare(b.kursinformation['Veranstalter'] || ''),
    },
    {
      title: 'Veranstaltungsort',
      dataIndex: ['kursinformation', 'Veranstaltungsort2'],
      key: 'veranstaltungsort',
      sorter: (a, b) => (a.kursinformation.Veranstaltungsort2 || '').localeCompare(b.kursinformation.Veranstaltungsort2 || ''),
    },
    {
      title: 'PLZ',
      dataIndex: ['kursinformation', 'PLZ'],
      key: 'plz',
      sorter: (a, b) => (a.kursinformation.PLZ || '').localeCompare(b.kursinformation.PLZ || ''),
    },
    {
      title: 'ICW Kursnummer',
      dataIndex: 'Kursnummer',
      key: 'kursnummer',
      sorter: (a, b) => (a.Kursnummer || '').localeCompare(b.Kursnummer || ''),
    },
    {
      title: 'Thema',
      dataIndex: ['kursinformation', 'Titel der Veranstaltung (Wundbezogen)'],
      key: 'thema',
      sorter: (a, b) => (a.kursinformation['Titel der Veranstaltung (Wundbezogen)'] || '').localeCompare(b.kursinformation['Titel der Veranstaltung (Wundbezogen)'] || ''),
    },
    {
      title: 'Punkte',
      dataIndex: 'Fortbildungspunkte',
      key: 'punkte',
      sorter: (a, b) => (a.Fortbildungspunkte || 0) - (b.Fortbildungspunkte || 0),
    },
    {
      title: 'Schulungstermin',
      key: 'schulungstermin',
      render: (text, record) => (
        <span style={{ color: "#eb008c" }}>
          {record.kursinformation.Veranstaltungsdatum[0] ?
            moment(record.kursinformation.Veranstaltungsdatum[0]).format('DD.MM.YY') : ''}
        </span>
      ),
      sorter: (a, b) => {
        const dateA = a.kursinformation.Veranstaltungsdatum[0] || '';
        const dateB = b.kursinformation.Veranstaltungsdatum[0] || '';
        return dateA.localeCompare(dateB);
      },
    },
    /*{
      title: 'Weitere Schulungstage',
      key: 'weitereSchulungstage',
      render: (text, record) => (
        <span>
          {record.kursinformation.Veranstaltungsdatum.slice(1)
            .map(date => moment(date).format('DD.MM.YY HH:mm'))
            .join(', ')}
        </span>
      ),
    },*/
    {
      title: 'Adresse',
      dataIndex: ['kursinformation', 'Straße mit Hausnummer'],
      key: 'adresse',
      sorter: (a, b) => (a.kursinformation['Straße mit Hausnummer'] || '').localeCompare(b.kursinformation['Straße mit Hausnummer'] || ''),
    },
  ];

  const makeVisible = (element) => {
    if (element.style && element.style.display === 'none') {
      element.style.display = 'block';
      element.setAttribute('data-print', 'true');
    }
    Array.from(element.children).forEach(makeVisible);
  };

  const makeInVisible = (element) => {
    if (element.getAttribute('data-print') === 'true') {
      element.style.display = 'none';
      element.removeAttribute('data-print');
    }
    Array.from(element.children).forEach(makeInVisible);
  };

  const printContent = (idToPrint) => {
    const content = document.getElementById(idToPrint).innerHTML;
    const printWindow = window.open('', '', 'height=800, width=1024');

    if (printWindow) {
      printWindow.document.open();

      const antdStyles = Array.from(document.styleSheets)
              .filter(sheet => sheet.href && sheet.href.includes('antd'))
              .map(sheet => {
                try {
                  return Array.from(sheet.cssRules)
                    .map(rule => rule.cssText)
                    .join('\n');
                } catch (e) {
                  return '';
                }
              })
              .join('\n');

            const styles = Array.from(document.styleSheets)
              .filter(sheet => sheet.href && (sheet.href.includes('antd') || sheet.href.includes('index.css')))
              .map(sheet => {
                try {
                  return Array.from(sheet.cssRules)
                    .map(rule => rule.cssText)
                    .join('\n');
                } catch (e) {
                  return '';
                }
              })
              .join('\n');

            printWindow.document.write(`
              <html>
                <head>
                  <title>Kursliste</title>
                  <style type="text/css">
                    ${styles}
                    @page {
                                    size: landscape;
                                    margin: 15mm;
                                  }

                                  body {
                                    margin: 0;
                                    padding: 20px;
                                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
                                  }

                                  h2 {
                                    margin-bottom: 20px;
                                    color: rgba(0, 0, 0, 0.85);
                                    font-weight: 600;
                                  }

                                  .ant-table {
                                    font-size: 8pt !important;
                                    width: 100% !important;
                                    table-layout: fixed !important;
                                  }

                                  .ant-table-container {
                                    overflow: visible !important;
                                  }

                                  .ant-table-content {
                                    overflow: visible !important;
                                  }

                                  .ant-table-body {
                                    overflow: visible !important;
                                  }

                                  .ant-table table {
                                    width: 100% !important;
                                    border-collapse: collapse !important;
                                  }

                                  .ant-table-cell {
                                    padding: 6px 4px !important;
                                    line-height: 1.2 !important;
                                    word-break: break-word !important;
                                    white-space: normal !important;
                                    border: 1px solid #f0f0f0 !important;
                                    background: #fff !important;
                                    overflow: visible !important;
                                  }

                                  .ant-table-thead > tr > th {
                                    background: #fafafa !important;
                                    color: rgba(0, 0, 0, 0.85) !important;
                                    font-weight: 500 !important;
                                    text-align: left !important;
                                    padding: 8px 4px !important;
                                    border: 1px solid #f0f0f0 !important;
                                    white-space: nowrap !important;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis !important;
                                  }

                                  /* Ensure column headers don't wrap but content cells can */
                                  /* Base cell styles */
                                  .ant-table-cell {
                                    padding: 6px 4px !important;
                                    line-height: 1.2 !important;
                                    border: 1px solid #f0f0f0 !important;
                                    background: #fff !important;
                                    overflow: visible !important;
                                  }

                                  /* Different styles for header vs content cells */
                                  .ant-table-tbody .ant-table-cell {
                                   word-break: normal !important;
                                   word-wrap: break-word !important;
                                   white-space: normal !important;
                                   overflow-wrap: break-word !important;
                                   hyphens: none !important;
                                  }

                                  .ant-table-cell {
                                    padding: 4px;
                                     white-space: nowrap;
                                  }

                                  /* Zebra striping for odd rows */
                                  .ant-table-tbody > tr:nth-child(odd) > td {
                                    background-color: #fafafa !important;
                                  }

                                  /* Ensure even rows stay white */
                                  .ant-table-tbody > tr:nth-child(even) > td {
                                    background-color: #ffffff !important;
                                  }

                                  .ant-table-row:nth-child(even) .ant-table-cell {
                                    background: #fafafa !important;
                                  }

                                  /* Custom column widths */
                                  .ant-table-cell:nth-child(1) { width: 7%; }   /* Ort */
                                  .ant-table-cell:nth-child(2) { width: 9%; }   /* Name */
                                  .ant-table-cell:nth-child(3) { width: 11%; }  /* Veranstaltungsort */
                                  .ant-table-cell:nth-child(4) { width: 5%; }   /* PLZ */
                                  .ant-table-cell:nth-child(5) { width: 7%; }   /* ICW Kursnummer */
                                  .ant-table-cell:nth-child(6) { width: 18%; }  /* Thema */
                                  .ant-table-cell:nth-child(7) { width: 4%; }   /* Punkte */
                                  .ant-table-cell:nth-child(8) { width: 9%; }   /* Schulungstermin */
                                  .ant-table-cell:nth-child(9) { width: 11%; }  /* Weitere Schulungstage */
                                  .ant-table-cell:nth-child(10) { width: 12%; } /* Adresse */
                                  .ant-table-cell:nth-child(11) { width: 7%; }  /* Telefon */

                                  .printHide {
                                    display: none !important;
                                  }

                                  .ant-table-column-sorter {
                                    display: none !important;
                                  }

                                  .signature-section {
                                    margin-top: 30px;
                                    page-break-inside: avoid;
                                  }

                                  .signature-section h3 {
                                    color: rgba(0, 0, 0, 0.85);
                                    font-weight: 500;
                                    margin-bottom: 15px;
                                  }

                                  .signature-line {
                                    margin-top: 50px;
                                    border-top: 1px solid #d9d9d9;
                                    width: 100%;
                                  }
                  </style>
                </head>
                <body>
                  <h2>Kursliste</h2>
                  ${content}
                </body>
              </html>
            `);

      setTimeout(() => {
        printWindow.print();
        setTimeout(() => {}, 1000);
      }, 1000);
    }
  };

  const fillForm = async () => {
    const form = document.getElementById("antragForm");
    makeVisible(form);
    setTimeout(() => {
      printContent("antragForm");
      makeInVisible(form);
    }, 100);
  };

  const filteredData = veranstaltungenData
  .filter(kurs => kurs.kursinformation && kurs.kursinformation["Die Veranstaltung soll in den Listen der ICW"] === "veröffentlicht werden")
  .map((kurs) => {
    const anbieterInfo = anbieterData.find(r => r.createdby === kurs.createdby);
    console.debug("AnbieterInfo: ", anbieterInfo);
    kurs.kursinformation.Veranstalter = anbieterInfo ? anbieterInfo.profilinformation.Institut : '';
    kurs.kursinformation.Veranstalterort = anbieterInfo ? anbieterInfo.profilinformation.Ort : '';

    return kurs;
  })
    .filter(kurs => {
      // Status filter
      if (currFilter && kurs.status !== currFilter) return false;

      // Date range filter
      if (dateRange && dateRange[0] && dateRange && dateRange[1]) {
        const kursinformation = kurs.kursinformation || {};
        const veranstaltungsdatum = kursinformation.Veranstaltungsdatum || [];
        const eventDate = veranstaltungsdatum[0] ? moment(veranstaltungsdatum[0]) : null;
        const dateStart = dateRange[0] ? dateRange[0].$d.getTime() : 0;
        const dateEnd = dateRange[1] ? dateRange[1].$d.getTime() : 0;
        const betweenDate = eventDate._d.getTime();
        const isBetween = betweenDate >= dateStart && betweenDate <= dateEnd;

        return isBetween ? true : false;
      }

      // Search filter
      if (currSearch) {
        const anbieterInfo = anbieterData.find(r => r.createdby === kurs.createdby);
        const anbieterDataString = JSON.stringify(anbieterInfo || {}) +
          moment(kurs.kursinformation.Veranstaltungsdatum[0] || "").format('DD.MM.YY HH:mm');
        return anbieterDataString.toLowerCase().includes(currSearch.toLowerCase()) ||
               JSON.stringify(kurs || {}).toLowerCase().includes(currSearch.toLowerCase());
      }

      return true;
    });

  return (
    <div>
      <div style={{textAlign: 'left', marginBottom: 16}}>
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <RangePicker
              locale="de"
              format="YYYY-MM-DD"
              onChange={(dates) => setDateRange(dates)}
            />
          </Col>
          <Col md={12} lg={12} sm={24} xs={24}>
            <Button
              className="printHide"
              onClick={() => fillForm()}
              icon={<FilePdfOutlined />}
            >
              Als PDF exportieren
            </Button>
          </Col>
        </Row>
      </div>

      <div id="antragForm">
        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey={(record) => record.id || Math.random().toString()}
          scroll={{ x: true }}
          pagination={false}
          locale={{ emptyText: 'Keine Anträge gefunden' }}
        />
      </div>
    </div>
  );
};

export default KurseList;