import React from "react";
import { UserOutlined, InfoCircleOutlined, FileTextOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons';

export const formConfig = [
  {
    title: '1. Veranstaltungsinformationen',
    icon: <InfoCircleOutlined />,
    forms: [

      {
      required: true,
        label: 'Titel der Veranstaltung (Wundbezogen)',
        type: 'string',
        key: 'Titel der Veranstaltung (Wundbezogen)',
      },
      {
      required: true,
        label: 'Veranstaltungsdatum und Uhrzeit',
        type: 'date',
        key: 'Veranstaltungsdatum',
      },
      {
      required: true,
        label: 'Seminartyp',
        type: 'select',
        key: 'Seminartyp',
        options: [
          'Präsenz-Seminar Formal geschlossen',
          'Präsenz-Seminar Formal offen',
          'Präsenz-Seminar Formal offen (jedoch mit Einzelnachweisen pro Vortrag und Teilnehmer)',
          'Web-Seminar (Online-Kurs separater Antrag) Formal geschlossen',
          'Web-Seminar (Online-Kurs separater Antrag) Formal offen',
          'Hybrid-Seminar (Präsenz und Web-Seminar parallel) Formal offen',
        ],
      },
      {
      required: true,
        label: 'Ausschreibung/ Flyer',
        type: 'multiple select',
        key: 'Ausschreibung',
        options: [
          'Der Flyer (Ausschreibung ist als Anlage beigefügt',
          'Es wird kein Flyer bzw. keine Kursausschreibung eingesetzt',
          'Die Veranstaltung wird online beworben',
        ],
      },
      {
        label: 'Flyer/ Anlagen',
        type: 'upload',
        key: 'anlagen',
      },
      {
        label: 'Mit Kooperationspartner',
        type: 'string',
        key: 'Mit Kooperationspartner',
      },
      {
        label: 'Firmenveranstaltung/ Produktausstellung',
      required: true,
        type: 'select',
        key: 'stellvertretung_fachdozent_basisqualifikation',
        options: [
          'Finden nur in separaten Räumen oder nur vor/nach der Veranstaltung statt',
          'Findet nicht statt',
        ],
      },
      {
        label: 'Link zum Web-Seminar',
        type: 'string',
        key: 'Link zum Web-Seminar',
      },
      {
        label: 'Zugangspasswort für die Zertifizierungsstelle',
        type: 'string',
        key: 'Zugangspasswort für die Zertifizierungsstelle',
      },
      {
      required: true,
        label: 'Nähere Angaben zum Veranstaltungsort (Gebäude, Etage, Raum)',
        type: 'string',
        key: 'Veranstaltungsort',
      },
      {
      required: true,
        label: 'Ort',
        type: 'string',
        key: 'Veranstaltungsort2',
      },
      {
      required: true,
        label: 'Postleitzahl',
        type: 'string',
        key: 'PLZ',
      },
      {
      required: true,
        label: 'Straße mit Hausnummer',
        type: 'string',
        key: 'Straße mit Hausnummer',
      },
      {
      required: true,
        label: 'Die Veranstaltung soll in den Listen der ICW:',
        type: 'select',
        key: 'Die Veranstaltung soll in den Listen der ICW',
        options: ['veröffentlicht werden', 'nicht veröffentlicht werden'],
      },
      {
      required: true,
        label: 'Die Veranstaltung soll auf PMP.online:',
        type: 'select',
        key: 'Die Veranstaltung soll auf PMP.online',
        options: ['veröffentlicht werden', 'nicht veröffentlicht werden'],
      },
      {
      required: true,
        label: 'Verantwortlicher des Anbieters vor Ort:',
        type: 'string',
        key: 'Verantwortlicher des Anbieters vor Ort',
      },
      {
      required: true,
        label: 'Dieser ist in die Vorgaben der Zertifizierungsstelle eingewiesen.',
        type: 'checkbox',
        key: 'Dieser ist in die Vorgaben der Zertifizierungsstelle eingewiesen',
      },
      {
      required: true,
        label: 'Ansprechpartner bei Rückfragen',
        type: 'string',
        key: 'Vorname/Nachname Antragsteller',
      },
      {
      required: true,
        label: 'Ich bestätige, dass wir die Vorgaben zur Ausrichtung von Rezertifizierungsveranstaltungen umsetzen.',
        type: 'checkbox',
        key: 'Ich bestätige, dass wir die Vorgaben zur Ausrichtung von Rezertifizierungsveranstaltungen umsetzen',
      },
      {
        label: 'Der Film "Erklärs mir - ICW Rezertifizierung" oder ICW Präsentation Rezertifizierung Teilnehmer wird zur Einführung gezeigt.',
        type: 'checkbox',
        required: true,
        key: 'dervideo',
      },
    ],
  },
  {
    title: '2. Seminar-Details',
    icon: <SolutionOutlined />,
   htmlbox: `Für einen Rezertifizierungskurs können auch verschiedene Seminarthemen genehmigt werden, welche aufgeschlüsselt mit Punkten bewertet werden. Für weitere Seminarpunkte, klicken Sie den Button "Seminarpunkt hinzufügen" unten.`,
    isArray: true,
    forms: [
      {
      required: true,
        label: 'Dozent:',
        type: 'select',
        key: 'Dozent',
      },
      {
      required: true,
        label: 'Seminarthema:',
         type: 'multiple select',
        key: 'Seminarthema',
        options:  [ 'Dekubitus/Prophylaxe', 'Diabetisches Fußsyndrom/Prophylaxe', 'Edukation', 'Expertenstandard', 'Haut/Hautpflege', 'Hygiene', 'Recht', 'Palliativ/Tumorwunden', 'Ulcus Cruris', 'Schmerz', 'Wundversorgung/Wundverbände', 'Wundarten und -heilung', 'Wundbeurteilung/Dokumentation', 'Kompression' ] ,
      },
      {
      required: true,
        label: 'Dauer in Minuten:',
        type: 'number',
        key: 'Dauer in Minuten',
      },
      {
        label: 'Ggf. abweichender Titel aus Flyer oder Ausschreibung',
        type: 'string',
        key: 'abweichender Titel',
      },
    ],
  },
];