import React, { useState, useEffect } from 'react';
import { Rate, List,Checkbox, Row, Col, Button, Input, Form, Switch, Select, Alert, Card, message, Radio, Tabs } from 'antd';
import DraggableModal from './DraggableModal';
import { EyeOutlined, DeleteOutlined, PlusOutlined, CommentOutlined, HistoryOutlined, CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { formConfig } from "../configs/KursAnerkennung";
import { API } from "../constant";
import { getToken, formatChanges } from "../helpers";

const { Option } = Select;

const StatusEnum = {
  NOT_SUBMITTED: 'Noch nicht eingereicht',
  SUBMITTED_IN_PROGRESS: 'Eingereicht - in Bearbeitung',
  SUBMITTED_REJECTED: 'Eingereicht - abgelehnt',
  SUBMITTED_ACCEPTED: 'Eingereicht - angenommen',
};

const KurseList = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isKommentarModalVisible, setIsKommentarModalVisible] = useState(false);
    const [isRevision, setIsRevision] = useState(false);
    const [currentKurs, setCurrentKurs] = useState(null);
    const [showAll, setShowAll] = useState(false)
    const [form] = Form.useForm();

    const [currSearch, setCurrSearch] = useState("");
    const [currFilter, setCurrFilter] = useState(StatusEnum.SUBMITTED_IN_PROGRESS);
    const [open, setOpen] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);

    const location = useLocation();
    useEffect(() => {
    if (
        location.state
    ) {
        if (location.state && location.state.open) {
          setOpen(location.state.open);
        }

        if (location.state && location.state.openApprove) {
          setOpenApprove(location.state.openApprove);
        }
    }
    // Update the ref with the current location state
  }, [location.pathname]);

 const anbieterData = props.anbieterData;
  const showModal = (id) => {
    setOpen(id);
  };

    const showModalApprove = (id) => {
      setOpenApprove(id);
    };

  const handleOk = () => {
    setOpen(false);
  };
  const handleApproveOk = () => {
    setOpenApprove(false);
  };
  const handleSearchChange = (e) => {
    setCurrSearch(e.target.value);
  };

  const handleApproveCancel = () => {
    setOpenApprove(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const Kurse = props.veranstaltungenData;
  const showEditModal = (kurs) => {
    setCurrentKurs(kurs);
    setIsModalVisible(true);
    form.setFieldsValue(kurs);
  };

  const showKommentarModal = (kurs, isRevision) => {
    setCurrentKurs(kurs);
    setIsKommentarModalVisible(true);
    setIsRevision(!!isRevision);
  };

  const handleEdit = (dozentid) => {
   props.history.push(`/admin/aktionen/Seminare/${dozentid}`)
  };

  const handleDeny = (id, kommentar = "") => {
        fetch(`${API}/kurss/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
              body: JSON.stringify({ data: { kommentar: kommentar, status: "Eingereicht - abgelehnt" }}),
            })
              .then(response => response.json())
              .then(data => { console.log(data);
                  if (data.error) {
//                              message("", "Es ging etwas schief");
                  } else {
//                              message("", "Kurs gelöscht. .");
                      props.history.push('/admin/aktionen/Seminarverwaltung', {openApprove: false, open: false })

                      window.location.reload();
                  }
              })
              .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
               });
      };

  const handleApprove = (id, anbieternummer, Kursgebuehr, Fortbildungspunkte) => {

             fetch(`${API}/kurss/${id}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                    body: JSON.stringify({ data: {Kursgebuehr: Kursgebuehr,  Kursnummer: anbieternummer, Fortbildungspunkte: Fortbildungspunkte, status: "Eingereicht - angenommen"} }),
                })
                      .then(response => response.json())
                      .then(data => { console.log(data);
                          if (data.error) {
                          message.open({ type: 'error', content: ((data.error || {}).message || "").indexOf("unique") > -1 ? "Die Kursnummer ist schon vergeben." : 'Beim Speichern ist ein Fehler aufgetreten.', className: 'custom-class', style: { padding: '3rem', marginTop: '20vh', fontSize: '20px', backgroundColor: '#eb008c', fontWeight: 800 }, })
                          } else {
props.history.push('/admin/aktionen/Seminarverwaltung', {openApprove: false, open: false })
                                window.location.reload();
                          }
                      })
                      .catch((error) => { console.error('Error:', error);
                            message.open({ type: 'error', content: ((error || {}).message || "").indexOf("unique") > -1 ? "Die Kursnummer ist schon vergeben." : 'Beim Speichern ist ein Fehler aufgetreten.', className: 'custom-class', style: { padding: '3rem', marginTop: '20vh', fontSize: '20px', backgroundColor: '#eb008c', fontWeight: 800 }, })
                       });
  };
  const handleModeChange = (e) => {
    setCurrFilter(e.target.value);
  };

  const handleAdd = () => {
//    const newKurs = { id: Date.now(), name: '', status: StatusEnum.NOT_SUBMITTED, kommentar: '', updatedAt: moment().toISOString() };
//    setKurse([...Kurse, newKurs]);
//    showEditModal(newKurs);
  };

  return (
    <div>
    <div style={{textAlign: 'left' }}>
       <Row gutter={[16, 16]}>
                         <Col md={8} lg={8} sm={24} xs={24}>
           <Input  onChange={handleSearchChange} style={{minWidth: 260 }} placeholder="Filter Anträge..." />
                      </Col><Col md={12} lg={12} sm={24} xs={24}>
                      <Radio.Group
                        onChange={handleModeChange}
                        value={currFilter}
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        <Radio.Button value="">Alle Anträge anzeigen</Radio.Button>
                        <Radio.Button value={StatusEnum.SUBMITTED_IN_PROGRESS}>Offene</Radio.Button>
                        <Radio.Button value={StatusEnum.SUBMITTED_REJECTED}>Abgelehnt</Radio.Button>
                        <Radio.Button value={StatusEnum.SUBMITTED_ACCEPTED}>Akzeptiert</Radio.Button>
                      </Radio.Group>
                      </Col>
                      </Row>
                      </div>

      <List
        dataSource={Kurse.filter(kurs => currFilter === "" || kurs.status === currFilter)
            .filter(kurs => {
                const anbieterDataString = JSON.stringify((((props.anbieterData || []).find(r => r.createdby === kurs.createdby) || {}) || {})) + moment((((kurs || {}).kursinformation || {})["Veranstaltungsdatum"] || {})[0] || "" ).format('DD.MM.YY HH:mm');
                console.debug("kurs", kurs);
                return currSearch === "" || anbieterDataString.toLowerCase().includes((currSearch || "").toLowerCase()) || JSON.stringify(kurs || {}).toLowerCase().includes((currSearch || "").toLowerCase())
            }).sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
        }

        renderItem={(kurs) => {

         const { kursinformation: { PLZ, Veranstaltungsort2 } = {} } = kurs || {};

         const anbieterDataRelatedId = (((anbieterData || []).find(r => r.createdby === kurs.createdby) || {}) || {}).anbieternummer;

         return (
          <Card
            bordered={false}
            style={{ marginBottom: 16 }}
          >
            <List.Item
              actions={[
                <Button icon={<EyeOutlined />}
                  onClick={() => props.history.push(`/admin/aktionen/Seminar/${kurs.id}`)   }
                 >Anzeigen</Button>,
                 kurs.status === StatusEnum.SUBMITTED_IN_PROGRESS ? <Button icon={<CloseCircleOutlined />} danger onClick={() => showModal(kurs.id)}>Ablehnen</Button> : null,
                 kurs.status === StatusEnum.SUBMITTED_IN_PROGRESS ? <Button icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }} onClick={() => showModalApprove(kurs.id)}>{ kurs.Kursnummer ? "Änderung akzeptieren" : "Genehmigen" }</Button> : null,

              ]}
            >


              <List.Item.Meta title={((kurs || {}).kursinformation || {})["Titel der Veranstaltung (Wundbezogen)"] + ((kurs || {}).Kursnummer ? (" (" + (kurs || {}).Kursnummer + ")") : "") + ((PLZ || Veranstaltungsort2) ? (' in ' + PLZ + ' ' + Veranstaltungsort2) : '')} />
{ anbieterDataRelatedId && <List.Item.Meta title={<span>Anbieter: {anbieterDataRelatedId}</span>} /> }
              <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                              message={<span>{kurs.status} {(kurs.status === StatusEnum.SUBMITTED_REJECTED || kurs.kommentar && (
                                     <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(kurs)}>Kommentar</Button>
                                   ))}

                                   {props.isICW && (((kurs || {}).kursinformation || {}).revision && <Button icon={<HistoryOutlined />} onClick={() => showKommentarModal(kurs, true)}>Änderungen</Button>)}

                                    {(kurs.Fortbildungspunkte && (<><p>{ kurs.status !== StatusEnum.SUBMITTED_ACCEPTED ? (<div><span style={{color: 'red'}}>Bei letzter Genehmigung: </span><br/></div>) : (<span></span>) }
                                                                         <span>Fortbildungspunkte:</span> <span>{kurs.Fortbildungspunkte}</span></p>
                                                                         <p>
                                                                          <span>Kursgebühr:</span> <span>{kurs.Kursgebuehr}</span></p>
                                                                                                                                                  </>
                                                                       ))}
                                                                       </span>}
                              type={
                                kurs.status === StatusEnum.NOT_SUBMITTED
                                  ? 'info'
                                  : kurs.status === StatusEnum.SUBMITTED_IN_PROGRESS
                                  ? 'warning'
                                  : kurs.status === StatusEnum.SUBMITTED_REJECTED
                                  ? 'error'
                                  : 'success'
                              }
                            /> } />
                <List.Item.Meta title={kurs.updatedAt && <span>Datum: <span style={{ color: "#eb008c" }} >{moment((((kurs || {}).kursinformation || {})["Veranstaltungsdatum"] || {})[0] || "" ).format('DD.MM.YY HH:mm')}</span></span>} />
                <List.Item.Meta title={kurs.updatedAt && <span>Letztes Update: {moment(kurs.updatedAt).format('DD.MM.YY HH:mm')}</span>} />
            </List.Item>
          </Card>
        )}}
  locale={{ emptyText: 'Keine Anträge gefunden' }}
      />
      <DraggableModal
        title="Kurs bearbeiten"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Bitte wählen Sie einen Status aus' }]}
          >
            <Select>
              <Option value={StatusEnum.NOT_SUBMITTED}>Anerkennung nicht gestartet</Option>
              <Option value={StatusEnum.SUBMITTED_IN_PROGRESS}>Anerkennung in Bearbeitung</Option>
              <Option value={StatusEnum.SUBMITTED_REJECTED}>Anerkennung abgelehnt</Option>
              <Option value={StatusEnum.SUBMITTED_ACCEPTED}>Anerkannt</Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('status') === StatusEnum.SUBMITTED_REJECTED && (
            <Form.Item
              name="kommentar"
              label="Kommentar"
              rules={[{ required: true, message: 'Bitte geben Sie einen Kommentar ein' }]}
            >
              <Input.TextArea />
            </Form.Item>
          )}
        </Form>
      </DraggableModal>
      <DraggableModal
        title={ isRevision ? "Verlauf" : "Kommentar" }
        visible={isKommentarModalVisible}
        width={ isRevision ? "85vw" : "50vw" }
        onCancel={() => setIsKommentarModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsKommentarModalVisible(false)}>
            Schließen
          </Button>,
        ]}
      >
         { isRevision ? formatChanges(((currentKurs || {}).kursinformation || {}).revision, formConfig) : <div dangerouslySetInnerHTML={{ __html: currentKurs ? currentKurs.kommentar : '' }} />}
      </DraggableModal>

      <DraggableModal
                style={{ minWidth: 600 }}
                closable={true}
                open={openApprove}
                title="Kursnummer/ Kursgebühr zuweisen und genehmigen"
                onOk={handleApproveOk}
                onCancel={handleApproveCancel}
                footer={null}
              > <Form
                   name="nest-messages"
                   initialValue={{ Kursnummer: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Kursnummer, Kursgebuehr: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Kursgebuehr, Fortbildungspunkte: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Fortbildungspunkte }}

  fields={[
    {
      name: ["Kursnummer"],
      value: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Kursnummer,
    },
    {
        name: ["Kursgebuehr"],
        value: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Kursgebuehr,
    },
    {
        name: ["Fortbildungspunkte"],
        value: (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Fortbildungspunkte,
    },
  ]}
                   onFinish={(values) => {
                      console.debug("values", values);
                      handleApprove(openApprove, values.Kursnummer, values.Kursgebuehr, values.Fortbildungspunkte);
                  }}
                   style={{ maxWidth: 600 }}
                 >
                   <Form.Item
                   rules={[
                           {
                               required: true,
                               message: 'Eine Kursnummer muss vergeben werden.',
                           },
                       ]}
                   name={'Kursnummer'} label="Kursnummer">
                     <Input v />
                   </Form.Item>
                   <Form.Item
                      rules={[
                                                  {
                                                      required: true,
                                                      message: 'Eine Kursgebühr muss angegeben werden.',
                                                  },
                                              ]}
                      name={'Kursgebuehr'} label="Kursgebühr">
                          <Select  defaultValue={(props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Kursgebuehr}>
                             <Option value="50,00 €">50,00 €</Option>
                             <Option value="70,00 €">70,00 €</Option>
                             <Option value="95,00 €">95,00 €</Option>
                             <Option value="110,00 €">110,00 €</Option>
                             <Option value="150,00 €">150,00 €</Option>
                             <Option value="250,00 €">250,00 €</Option>
                          </Select>
                   </Form.Item>
                   <Form.Item
                   rules={[
                           {
                               required: true,
                               message: 'Fortbildungspunkte müssen vergeben werden.',
                           },
                       ]}
                   name={'Fortbildungspunkte'} label="Fortbildungspunkte">
                     <Rate
                                         count={16}
                                         style={{ color: '#eb008c' }}
                                         defaultValue={0}
                                         character={({ index }: { index: number }) => index + 1}
                                         name={'Fortbildungspunkte'}
                                       />
                   </Form.Item>
                   <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
                   <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }}>
                   { (props.veranstaltungenData.find(k=> k.id === openApprove) || {}).Kursnummer ? "Änderung genehmigen" : "Genehmigung abschließen" }</Button>
                   </Form.Item>
                 </Form>
              </DraggableModal>

      <DraggableModal
          closable={true}
          open={open}
          title="Begründung für Ablehnung"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        > <Form
             name="nest-messages"
             onFinish={(values) => {
                console.debug("values", values);
                handleDeny(open, values.kommentar);
            }}
             style={{ maxWidth: 600 }}
           >
             <Form.Item name={'kommentar'} label="Begründung">
               <Input.TextArea />
             </Form.Item>
             <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
               <Button type="primary" htmlType="submit">
                 Mit obiger Begründung ablehnen
               </Button>
             </Form.Item>
           </Form>
        </DraggableModal>
    </div>
  );
};

export default KurseList;
