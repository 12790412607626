import React, { useState, useEffect } from 'react';
import { message, List, Button, Radio, Input, Form, Select, Alert, Card, Row, Col } from 'antd';
import DraggableModal from './DraggableModal';
import { EditOutlined, DeleteOutlined, HistoryOutlined, PlusOutlined, CommentOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formConfig } from "../configs/DozentAnerkennung";
import { API } from "../constant";
import { getToken, formatChanges } from "../helpers";

const { Option } = Select;

const StatusEnum = {
  NOT_SUBMITTED: 'Noch nicht eingereicht',
  SUBMITTED_IN_PROGRESS: 'Eingereicht - in Bearbeitung',
  SUBMITTED_REJECTED: 'Eingereicht - abgelehnt',
  SUBMITTED_ACCEPTED: 'Eingereicht - angenommen',
};

const DozentenList = (props) => {
//  const [dozenten, setDozenten] = useState([
//    { id: 1, name: 'Dr. John Doe', status: StatusEnum.SUBMITTED_ACCEPTED, kommentar: '', updatedAt: '2024-05-01T10:00:00Z' },
//    { id: 2, name: 'Prof. Jane Smith', status: StatusEnum.SUBMITTED_IN_PROGRESS, kommentar: '', updatedAt: '2024-05-15T14:00:00Z' },
//    { id: 3, name: 'Dr. Test Abgelehnt', status: StatusEnum.SUBMITTED_REJECTED, kommentar: 'Das ist der Grund...d dsa', updatedAt: '2024-05-20T18:00:00Z' },
//  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isKommentarModalVisible, setIsKommentarModalVisible] = useState(false);
  const [currSearch, setCurrSearch] = useState("");
  const [currFilter, setCurrFilter] = useState("");

    const [isRevision, setIsRevision] = useState(false);
  const [currentDozent, setCurrentDozent] = useState(null);
  const [form] = Form.useForm();

  const dozenten = props.veranstaltungenData;
  const showEditModal = (dozent) => {
    setCurrentDozent(dozent);
    setIsModalVisible(true);
    form.setFieldsValue(dozent);
  };

  const showKommentarModal = (dozent,isRevision) => {
    setCurrentDozent(dozent);
    setIsRevision(!!isRevision);
    setIsKommentarModalVisible(true);
  };

  const handleEdit = (dozentid) => {
   props.history.push(`/admin/aktionen/Dozenten/${dozentid}`)
    console.log('handleEdit');
//    form.validateFields().then(values => {
//      setDozenten((prev) =>
//        prev.map((dozent) =>
//          dozent.id === currentDozent.id ? { ...dozent, ...values } : dozent
//        )
//      );
//      setIsModalVisible(false);
//      setCurrentDozent(null);
//    });
  };


  const handleSearchChange = (e) => {
    setCurrSearch(e.target.value);
  };
  const handleModeChange = (e) => {
    setCurrFilter(e.target.value);
  };

  const handleDelete = (id) => {

 fetch(`${API}/dozents/${id}`, {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                })
                      .then(response => response.json())
                      .then(data => { console.log(data);
                          if (data.error) {
//                              message("", "Es ging etwas schief");
                          } else {
//                              message("", "Kurs gelöscht. .");
                              window.location.reload();
                          }
                      })
                      .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
                       });
  };
  const sortedDozenten = dozenten.filter(dozent => currFilter === "" || dozent.status === currFilter)
                           .filter(dozent => currSearch === "" || JSON.stringify(dozent || {}).toLowerCase().includes((currSearch || "").toLowerCase()))
                           .sort((a, b) => ((a || {}).dozentinformation || {})["Name"] > ((b || {}).dozentinformation || {})["Name"] ? 1 : -1)

  return (
    <div>
    <div style={{textAlign: 'left' }}>
      <Button type="primary" icon={<PlusOutlined />}
      onClick={() => props.history.push(`/admin/aktionen/Dozent`) }
      style={{textAlign: 'left', marginBottom: 16 }}>
        Neuen Dozenten hinzufügen
      </Button>
      </div>
      <div style={{textAlign: 'left' }}>
             <Row gutter={[16, 16]}>
                               <Col md={8} lg={8} sm={24} xs={24}>
                 <Input  onChange={handleSearchChange} style={{minWidth: 260 }} placeholder="Filter Anträge..." />
                            </Col><Col md={12} lg={12} sm={24} xs={24}>
                  <Radio.Group
                              onChange={handleModeChange}
                              value={currFilter}
                              style={{
                                marginBottom: 8,
                              }}
                            >
                              <Radio.Button value="">Alle Anträge anzeigen</Radio.Button>
                              <Radio.Button value={StatusEnum.SUBMITTED_IN_PROGRESS}>Offene</Radio.Button>
                              <Radio.Button value={StatusEnum.SUBMITTED_REJECTED}>Abgelehnt</Radio.Button>
                              <Radio.Button value={StatusEnum.SUBMITTED_ACCEPTED}>Akzeptiert</Radio.Button>
                            </Radio.Group>
                            </Col>
                            </Row>
                            </div>
      <List
        dataSource={sortedDozenten.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))}
        renderItem={(dozent) => (
          <Card
            bordered={false}
            style={{ marginBottom: 16 }}
          >
            <List.Item
              actions={[
                <Button icon={<EditOutlined />}


                onClick={() => props.history.push(`/admin/aktionen/Dozenten/${dozent.id}`) }
      >Bearbeiten</Button>,
                <Button icon={<DeleteOutlined />} danger onClick={() => handleDelete(dozent.id)}>Löschen</Button>,
              ]}
            >
              <List.Item.Meta title={((dozent || {}).dozentinformation || {}).Vorname + " " + ((dozent || {}).dozentinformation || {}).Name} />

                <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                                              message={<span>{dozent.status} {(dozent.status === StatusEnum.SUBMITTED_REJECTED && (
                                                     <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(dozent)}>Kommentar</Button>
                                                   ))}
                                   {props.isICW && (((dozent || {}).dozentinformation || {}).revision && <Button icon={<HistoryOutlined />} onClick={() => showKommentarModal(dozent, true)}>Änderungen</Button>)}

                                                    {(dozent.Fortbildungspunkte && dozent.status === StatusEnum.SUBMITTED_ACCEPTED && (<p>
                                                                                                                             <span>Fortbildungspunkte:</span> <span>{dozent.Fortbildungspunkte}</span></p>
                                                                                                                           ))}
                                                                                                                           </span>}
                                              type={
                                                dozent.status === StatusEnum.NOT_SUBMITTED
                                                  ? 'info'
                                                  : dozent.status === StatusEnum.SUBMITTED_IN_PROGRESS
                                                  ? 'warning'
                                                  : dozent.status === StatusEnum.SUBMITTED_REJECTED
                                                  ? 'error'
                                                  : 'success'
                                              }
                                  /> } />
                                  
                <List.Item.Meta title={dozent.updatedAt && <span>Letztes Update: {moment(dozent.updatedAt).format('DD.MM.YY HH:mm')}</span>} />
            </List.Item>
          </Card>
        )}
  locale={{ emptyText: 'Keine Anträge gefunden' }}
      />
      <DraggableModal
        title="Dozent bearbeiten"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Bitte wählen Sie einen Status aus' }]}
          >
            <Select>
              <Option value={StatusEnum.NOT_SUBMITTED}>Anerkennung nicht gestartet</Option>
              <Option value={StatusEnum.SUBMITTED_IN_PROGRESS}>Anerkennung in Bearbeitung</Option>
              <Option value={StatusEnum.SUBMITTED_REJECTED}>Anerkennung abgelehnt</Option>
              <Option value={StatusEnum.SUBMITTED_ACCEPTED}>Anerkannt</Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('status') === StatusEnum.SUBMITTED_REJECTED && (
            <Form.Item
              name="kommentar"
              label="Kommentar"
              rules={[{ required: true, message: 'Bitte geben Sie einen Kommentar ein' }]}
            >
              <Input.TextArea />
            </Form.Item>
          )}
        </Form>
      </DraggableModal>
      <DraggableModal
        title={ isRevision ? "Verlauf" : "Kommentar" }
        visible={isKommentarModalVisible}
        width={ isRevision ? "85vw" : "50vw" }
        onCancel={() => setIsKommentarModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsKommentarModalVisible(false)}>
            Schließen
          </Button>,
        ]}
      >
         { isRevision ? formatChanges(((currentDozent || {}).dozentinformation || {}).revision, formConfig) : <div dangerouslySetInnerHTML={{ __html: currentDozent ? currentDozent.kommentar : '' }} />}
      </DraggableModal>
    </div>
  );
};

export default DozentenList;
